import ReactSelect from "react-select";
import { Label } from "reactstrap";

const options = [
  {
    label: "USD - Default",
    value: {
      accountNo: "U46844393",
      currency: "USD",
    }
  }
];

export default function PerfectMoney(props) {

  const {
    t, setIsFirstStepValid, setPaymentPayload
  } = props;
  const onChangeHandler = (e) => {
    if (e) {
      setPaymentPayload({
        ...e.value,
        gateway: "PERFECT_MONEY"
      });
      setIsFirstStepValid(true);
    } else {
      setPaymentPayload({});
      setIsFirstStepValid(false);
    }
  };
  return (
    <div>
      <p className="text-muted">{t("Select the deposit account")}</p>
      <div className="mb-3">
        <Label>{t("Account ")}</Label>
        <ReactSelect
          name="account"
          onChange={onChangeHandler}
          required
          placeholder="Select Account"
          options={options}
        ></ReactSelect>
      </div>
    </div>
  );
} 