import React, { useEffect, useRef } from "react";

const PMForm = (props) => {
  const data = props.result;
  const formRef = useRef(null);

  useEffect(() => {
    formRef.current.submit();
  }, []);

  return (
    <div>
      <form
        ref={formRef}
        action={"https://perfectmoney.com/api/step1.asp"}
        method="post"
        target="_top"
      >
        <input type="hidden" name="PAYEE_ACCOUNT" value={data.payeeAccount}></input>
        <input type="hidden" name="PAYEE_NAME" value="XeOne"></input>
        <input type="hidden" name="PAYMENT_ID" id='payment_id' value={data.orderId}></input>
        <input type="hidden" name="PAYMENT_AMOUNT" value={data.amount}></input>
        <input type="hidden" name="PAYMENT_UNITS" value={data.currency}></input>
        <input type="hidden" name="STATUS_URL" value={data.success}></input>
        <input type="hidden" name="PAYMENT_URL" value={data.cancel}></input>
        <input type="hidden" name="PAYMENT_URL_METHOD" value="LINK"></input>
        <input type="hidden" name="NOPAYMENT_URL" value={data.cancel}></input>
        <input type="submit" id='payment_method' name="PAYMENT_METHOD" value="Pay Now!" style={{
          display: "none"
        }}></input>
      </form>
    </div>
  );
};

export default PMForm;